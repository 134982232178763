import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import MarkdownContent from "../components/markdown"
import InstagramSlider from "../components/instagram-slider"
import Helmet from "react-helmet"
import LandingSlider from "../components/landing-slider"

const LandingPageTemplate = ({
  title,
  ctaText,
  ctaURL,
  ourStory,
  slider,
  bestsellers,
  firstTitle,
  secondTitle,
  thirdTitle,
  aboutBunty,
}) => (
  <Layout>
    <Seo title="Deliciae by Bunty Mahajan - Voted Best Patisserie in Mumbai" />
    <Helmet>
      <html lang="en" />
      <script type="application/ld+json">{`
		{
			"@context": "https://schema.org",
			"@type": "Bakery",
			"name": "Deliciae cakes",
			"image": "https://www.deliciaecakes.com/style/images/art/about13.jpg",
			"@id": "",
			"url": "https://www.deliciaecakes.com/",
			"telephone": "9029023000",
			"menu": "",
			"servesCuisine": "",
			"acceptsReservations": "false",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "Khar West",
				"addressLocality": "mumbai",
				"postalCode": "400052",
				"addressCountry": "IN"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": 19.073181,
				"longitude": 72.831208
			},
			"sameAs": [
				"https://www.facebook.com/delcakes.in/",
				"https://twitter.com/deliciaecakes",
				"https://www.instagram.com/delcakes.in/"
			]
		}
	`}</script>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Deliciae cakes",
            "url": "https://www.deliciaecakes.com/",
            "logo": "https://www.deliciaecakes.com/style/images/logo-light.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "9029023000",
              "contactType": "customer service",
              "areaServed": "IN",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.instagram.com/delcakes.in/",
              "https://www.facebook.com/delcakes.in/",
              "https://twitter.com/deliciaecakes"
            ]
          }
        `}
      </script>
    </Helmet>
    <LandingSlider />

    <div className={"light-bg py-5"}>
      <div className={"container my-5"}>
        <h2 className={"text-center"}>{firstTitle}</h2>
        {bestsellers}
      </div>
      <div className={"container-fluid"}>
        <h5 className={"my-5 text-center"}>{secondTitle}</h5>
        <div className={"container"}>
          <MarkdownContent content={ourStory} className={"montserrat"} />
          <div className={"social-icons"}>
            <a
              href="https://www.facebook.com/delcakes.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </a>
            <a
              href="https://www.instagram.com/delcakes.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </div>
        </div>
      </div>
      <div className={"container-fluid"}>
        <h5 className={"my-5 text-center"}>{thirdTitle}</h5>
        <div className={"container"}>
          <MarkdownContent content={aboutBunty} className={"montserrat"} />
        </div>
      </div>
      <div className="instagram-swiper">
        <div className="follow-me-on-instagram">
          <a
            href="https://www.instagram.com/delcakes.in"
            target="_blank"
            rel="noreferrer noopener"
          >
            Follow us on Instagram
          </a>
        </div>
        <InstagramSlider />
      </div>
    </div>
  </Layout>
)

LandingPageTemplate.propTypes = {
  about: PropTypes.string,
}

const LandingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const bestsellers = []
  frontmatter.rows.forEach((b) => {
    bestsellers.push(
      <Col md="6" className={"my-3 del-image-grid"}>
        <Img fluid={b.image.childImageSharp.fluid} alt={b.title} />
        <h4>{b.title}</h4>
        <MarkdownContent content={b.description} />
        <a href={b.buttonURL} class="btn btn-primary">
          {b.buttonText}
        </a>
      </Col>
    )
  })

  const bestsellersRow = <Row>{bestsellers}</Row>

  return (
    <LandingPageTemplate
      title={frontmatter.title}
      ctaText={frontmatter.ctaText}
      ctaURL={frontmatter.ctaURL}
      ourStory={frontmatter.ourStory}
      bestsellers={bestsellersRow}
      slider={frontmatter.slider}
      firstTitle={frontmatter.firstTitle}
      secondTitle={frontmatter.secondTitle}
      thirdTitle={frontmatter.thirdTitle}
      aboutBunty={frontmatter.aboutBunty}
    />
  )
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LandingPage
export const query = graphql`
  query LandingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "landing" } }) {
      frontmatter {
        title
        ctaText
        ctaURL
        ourStory
        aboutBunty
        firstTitle
        secondTitle
        thirdTitle
        rows {
          image {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
          buttonText
          buttonURL
        }
      }
    }
  }
`
