import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import Img from "gatsby-image"
import SwiperCore, { Autoplay } from "swiper"

SwiperCore.use([Autoplay])

const params = {
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 3000,
  },
}

const LandingSlider = () => (
  <StaticQuery
    query={graphql`
      query LandingPageImages {
        markdownRemark(frontmatter: { templateKey: { eq: "landing" } }) {
          frontmatter {
            slider {
              image {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              title
              text
              button_text
              url
            }
          }
        }
      }
    `}
    render={(data) => {
      const images = []
      data.markdownRemark.frontmatter.slider.forEach((slider, i) => {
        images.push(
          <SwiperSlide>
            <Img
              style={{ height: "100vh", width: "100vw" }}
              imgStyle={{ objectFit: "cover" }}
              fluid={slider.image.childImageSharp.fluid}
              alt={slider.title}
            />
            <div className="slider-overlay">
              <div>
                <h2>{slider.text}</h2>
                <a href={slider.url} class="btn btn-primary btn-lg">
                  {slider.button_text}
                </a>
              </div>
            </div>
          </SwiperSlide>
        )
      })
      return <Swiper {...params}>{images}</Swiper>
    }}
  />
)

export default LandingSlider
